import React from "react";
import { NavLink } from "react-router-dom";

function NotFound() {
  return (
    <div className="bg-itqan-green-200 h-screen flex justify-center items-center text-white flex-col gap-2">
      <img src="/assets/images/logo.png" alt="logo" className="w-40 mx-auto" />
      <h1 className="text-9xl font-black">404</h1>
      <p className="md:text-xl text-lg font-bold text-center">
        Halaman yang anda cari tidak tersedia!
      </p>
      <NavLink
        to="/dashboard"
        className="bg-itqan-green-50 mt-5 text-white font-bold py-2 px-4 rounded"
      >
        Kembali ke Beranda
      </NavLink>
    </div>
  );
}

export default NotFound;
