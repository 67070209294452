import React from "react";
import { useSelector } from "react-redux"; // import useSelector untuk mengambil data dari redux
import PropTypes from "prop-types"; // import PropTypes untuk mengecek tipe data props

const AuthRoles = ({
  roles, // props ini untuk menerima data roles yang dikirim dari komponen parent (bentuknya berupa array misalnya [Admin, Murabbi, ...]])
  children, // props ini untuk menerima data children yang dikirim dari komponen (children merupakan komponen yang akan di render yang bentuknya berupa tag html)
}) => {
  const userRole = useSelector((state) => state.auth.role); // mengambil data role dari redux dengan useSelector (state.auth.role artinya mengambil data role dari reducer auth yang berada di folder src/features/authSlice.js)

  return <>{roles.includes(userRole) && <>{children}</>}</>; // jika data roles yang dikirim dari komponen parent (userRole) mengandung data userRole yang diambil dari redux maka komponen children akan di render jika tidak maka komponen children tidak akan di render
};

// mengecek tipe data props yang dikirim dari komponen parent (untuk memastikan data yang dikirim sesuai dengan yang dibutuhkan)
AuthRoles.propTypes = {
  roles: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthRoles;
