import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setFullName, setRole, setUserName } from "../../features/authSlice";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [color, setColor] = useState("bg-itqan-green-200");
  const [errorMessage, setErrorMessage] = useState("Login");
  const [loading, setLoading] = useState("LOGIN");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     try {
  //       const refreshToken = Cookies.get("refreshToken") || "{}";
  //       const response = await axios.post(`${API_URL}/auth/refresh`, {
  //         refreshToken,
  //       });
  //       if (response?.status === 200) {
  //         navigate("/dashboard");
  //       } else {
  //         return;
  //       }
  //     } catch (error) {
  //       return;
  //     }
  //   };
  //   checkAuth();
  // }, [navigate, API_URL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const getUser = async () => {
    //   const accessToken = Cookies.get("accessToken");
    //   const headers = { Authorization: `Bearer ${accessToken}` };

    //   try {
    //     const response = await axios.get(`${API_URL}/users/profile`, {
    //       headers,
    //     });
    //     const { user } = response?.data?.data;

    //     if (user) {
    //       if (user.isAdmin) {
    //         dispatch(setRole("Admin"));
    //       } else if (user.isMurabbi) {
    //         dispatch(setRole("Murabbi"));
    //       } else if (user.isMasUl) {
    //         dispatch(setRole("Mas'ul"));
    //       } else {
    //         dispatch(setRole("Anggota"));
    //       }
    //       dispatch(setFullName(user.fullName));
    //       dispatch(setUserName(user.username));
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    const login = async () => {
      try {
        setLoading("Memverifikasi Data...");
        const response = await axios.post(`${API_URL}/auth/login`, {
          username: username,
          password: password,
        });
        if (response?.status === 200) {
          Cookies.set("accessToken", response.data.data.accessToken);
          Cookies.set("refreshToken", response.data.data.refreshToken, {
            expires: 30,
          });
          // getUser();
          navigate("/dashboard");
          setLoading("LOGIN");
        } else {
          setColor("bg-red-700");
          setErrorMessage("Server Error!");
          setLoading("Terjadi Kesalahan");
          setTimeout(() => {
            setColor("bg-itqan-green-200");
            setErrorMessage("Login");
            setLoading("LOGIN");
          }, 3000);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setColor("bg-red-700");
          setErrorMessage("Username / Password Salah!");
          setTimeout(() => {
            setLoading("LOGIN");
            setColor("bg-itqan-green-200");
            setErrorMessage("Login");
          }, 2000);
        } else {
          setColor("bg-red-700");
          setLoading("Terjadi Kesalahan");
          setErrorMessage("Server Error!");
          setTimeout(() => {
            setLoading("LOGIN");
            setColor("bg-itqan-green-200");
            setErrorMessage("Login");
          }, 2000);
        }
      }
    };
    login();
  };

  return (
    <div
      className={`${color} h-screen flex justify-center items-center bg-red`}
    >
      <form className="text-white md:w-420px w-80" onSubmit={handleSubmit}>
        <img
          src="/assets/images/logo.png"
          alt="logo"
          className="w-1/2 mx-auto"
        />
        <h1 className="text-center text-3xl font-bold my-3">{errorMessage}</h1>
        <div className="flex flex-col">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"
            id="username"
            onChange={(e) => setUsername(e.target.value)}
            className="h-12 focus:ring-itqan-green-100  focus:outline-itqan-green-100 text-black px-2 my-2 rounded-lg"
            required
          />

          <label htmlFor="password" className="mt-3">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            className="h-12 focus:ring-itqan-green-100  focus:outline-itqan-green-100 text-black px-2 my-2 rounded-lg"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-itqan-green-50 text-white w-56 h-12 rounded-lg mt-16 mb-8 font-semibold mx-auto block"
        >
          {loading}
        </button>
      </form>
    </div>
  );
};

export default Login;
