import React from "react";
import ReactLoading from "react-loading";

function Preloader() {
  return (
    <div className="h-screen flex flex-col gap-5 justify-center items-center">
      <ReactLoading
        className="text-center"
        type={"spinningBubbles"}
        color={"#007B55"}
        height={"100px"}
        width={"100px"}
      />
      <div className="text-black font-semibold text-xl">
        <h1>Memuat Halaman...</h1>
      </div>
    </div>
  );
}

export default Preloader;
