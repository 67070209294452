import React from "react";
import {
  mdiViewDashboardOutline,
  mdiNewspaperVariantOutline,
  mdiBookOpenPageVariantOutline,
  mdiChartBellCurve,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiClipboardAccountOutline,
  mdiMosqueOutline,
  mdiBadgeAccountHorizontalOutline,
  mdiBookAccountOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import AuthRoles from "../../Middlewares/AuthRoles";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Menu = ({ handleCloseSidebar }) => {
  const role = useSelector((state) => state.auth.role);

  return (
    <ul className="navbar-item space-y-4 font-medium mt-10">
      {/* dashboard */}
      <li>
        <NavLink
          onClick={handleCloseSidebar}
          to="/dashboard"
          className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
        >
          <Icon
            path={mdiViewDashboardOutline}
            size={1}
            className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
          />
          <span className="ml-3 group-hover:text-white">
            {`Dashboard ${role}`}
          </span>
        </NavLink>
      </li>

      {/* Anggota */}
      <AuthRoles roles={["Admin"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/anggota"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiAccountOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Anggota</span>
          </NavLink>
        </li>
      </AuthRoles>

      <AuthRoles roles={["Admin"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/ibadah"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiMosqueOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Ibadah</span>
          </NavLink>
        </li>
      </AuthRoles>

      <AuthRoles roles={["Mas'ul", "Murabbi", "Anggota"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/mutabaah-yaumiyah"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiNewspaperVariantOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">
              Mutabaah Yaumiyah
            </span>
          </NavLink>
        </li>
      </AuthRoles>

      {/* Laporan */}
      <AuthRoles roles={["Anggota", "Mas'ul", "Murabbi"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/laporan"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiChartBellCurve}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Laporan</span>
          </NavLink>
        </li>
      </AuthRoles>

      <AuthRoles roles={["Admin"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/laporan-anggota"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiBadgeAccountHorizontalOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Laporan Anggota</span>
          </NavLink>
        </li>
      </AuthRoles>
      <AuthRoles roles={["Admin"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/laporan-kelompok"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiBookAccountOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">
              Laporan kelompok
            </span>
          </NavLink>
        </li>
      </AuthRoles>

      {/* Materi */}
      <li>
        <NavLink
          onClick={handleCloseSidebar}
          to="/materi"
          className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
        >
          <Icon
            path={mdiBookOpenPageVariantOutline}
            size={1}
            className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
          />
          <span className="ml-3 group-hover:text-white">Materi</span>
        </NavLink>
      </li>

      {/* Kelompok */}
      <AuthRoles roles={["Admin", "Murabbi", "Mas'ul"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/kelompok"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiAccountGroupOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Kelompok</span>
          </NavLink>
        </li>
      </AuthRoles>

      <AuthRoles roles={["Admin"]}>
        <li>
          <NavLink
            onClick={handleCloseSidebar}
            to="/amal-usaha"
            className="flex items-center p-2 py-3 text-gray-900 rounded-lg bg-white group hover:bg-itqan-green-200"
          >
            <Icon
              path={mdiClipboardAccountOutline}
              size={1}
              className="w-6 h-6 text-black transition duration-75  group-hover:text-white "
            />
            <span className="ml-3 group-hover:text-white">Amal Usaha</span>
          </NavLink>
        </li>
      </AuthRoles>
    </ul>
  );
};

export default Menu;
