import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom"; // NavLink ini untuk membuat link yang akan ditampilkan pada sidebar (pengganti tag <a>)
import { useSelector, useDispatch } from "react-redux"; // useSelector ini untuk mengambil data dari redux state, useDispatch ini untuk mengirim data ke redux state
import Icon from "@mdi/react"; // Icon ini untuk menampilkan icon pada sidebar
import axios from "axios"; // axios ini untuk melakukan request ke API
import {
  mdiMenu,
  mdiBackburger,
  mdiShieldCrownOutline,
  mdiSchoolOutline,
} from "@mdi/js"; // mdiMenu ini untuk menampilkan icon menu, mdiBackburger ini untuk menampilkan icon back
import { resetAuth } from "../../features/authSlice"; // resetAuth ini untuk menghapus data auth pada redux state (logout)
import Cookies from "js-cookie"; // Cookies ini untuk mengambil data cookies
import { initDropdowns } from "flowbite"; // initDropdowns ini untuk menginisialisasi dropdown pada sidebar (flowbite)
import AuthMiddleware from "../../Middlewares/AuthMiddleware"; // AuthMiddleware ini untuk mengecek apakah user sudah login atau belum
import Menu from "./Menu"; // Menu ini untuk menampilkan menu pada sidebar

const SideNavbar = ({
  title, // title ini untuk judul halaman yang akan ditampilkan pada sub Navbar
  children, // children ini untuk menampilkan komponen yang akan dibungkus oleh sidebar
  backButton, // backButton ini untuk menampilkan tombol back pada sub Navbar (true/false) jika tidak dipanggil maka tombol back tidak akan ditampilkan
}) => {
  const API_URL = process.env.REACT_APP_API_URL; // API_URL ini untuk memanggil url dari API yang ada pada file .env
  const fullName = useSelector((state) => state.auth.fullName); // fullName ini untuk mengambil data fullName dari redux state
  const userName = useSelector((state) => state.auth.userName); // userName ini untuk mengambil data userName dari redux state
  const profilePicture = useSelector((state) => state.auth.profilePicture); // profilePicture ini untuk mengambil data profilePicture dari redux state
  const role = useSelector((state) => state.auth.role); // role ini untuk mengambil data role dari redux state
  const [showSidebar, setShowSidebar] = useState(true); // showSidebar ini untuk menampilkan sidebar (true/false)
  const navigate = useNavigate(); // navigate ini untuk berpindah halaman
  const dispatch = useDispatch(); // dispatch ini untuk menyimpan data ke redux state
  useEffect(() => {
    if (window.innerWidth < 900) {
      // Jika lebar layar kurang dari 900px maka sidebar akan disembunyikan
      setShowSidebar(false); // tutup sidebar
    }
    window.addEventListener("resize", () => {
      // Jika lebar layar kurang dari 900px maka sidebar akan disembunyikan
      if (window.innerWidth < 900) {
        setShowSidebar(false); // tutup sidebar
      } else {
        setShowSidebar(true); // buka sidebar
      }
    });
    setTimeout(() => {
      // setTimeout ini untuk menunggu sidebar selesai di render
      initDropdowns(); // inisialisasi dropdown pada sidebar (flowbite) dengan delay 1 detik
    }, 1000);
  }, []);

  const handleCloseSidebar = () => {
    // handleCloseSidebar ini untuk menutup sidebar (dipanggil jika component menu pada sidebar di klik)
    if (window.innerWidth > 1024) return; // Jika lebar layar lebih dari 1024px maka sidebar tidak akan ditutup
    setShowSidebar(false);
  };

  const handleLogout = () => {
    // handleLogout ini untuk melakukan logout
    const logout = async () => {
      // function ini dibuat async karena kita akan melakukan request ke API untuk melakukan logout
      try {
        const response = await axios.patch(`${API_URL}/auth/logout`, {
          // request ke API untuk melakukan logout
          refreshToken: Cookies.get("refreshToken"), // kirim refreshToken yang ada pada cookies untuk melakukan logout
        });
        Cookies.remove("accessToken"); // hapus accessToken yang ada pada cookies
        Cookies.remove("refreshToken"); // hapus refreshToken yang ada pada cookies
        dispatch(resetAuth()); // hapus semua data auth pada redux state
        navigate("/login"); // pindahkan ke halaman login
      } catch (error) {
        // jika terjadi error
        console.log(error); // tampilkan error pada console
      }
    };
    logout(); // panggil function logout
  };

  return (
    <>
      {/*Semua component sidebar ini dibungkus oleh AuthMiddleware, yang mana AuthMiddleware ini berfungsi untuk mengecek apakah user sudah login atau belum, jika belum maka user akan dipindahkan ke halaman login*/}
      <AuthMiddleware>
        <nav className="sidebar bg-itqan-green-200 text-white font-bold w-full h-12 fixed z-50 px-6 flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <button onClick={() => setShowSidebar(!showSidebar)}>
              <Icon
                path={`${showSidebar ? mdiBackburger : mdiMenu}`}
                size={1}
                className="w-6 h-6"
              />
            </button>
            <h1>Majelis Pembinaan Itqan</h1>
          </div>
          <div className="flex items-center gap-1">
            {role === "Admin" && <Icon path={mdiShieldCrownOutline} size={1} />}
            {role === "Murabbi" && <Icon path={mdiSchoolOutline} size={1} />}
            <div
              className="flex items-center gap-2 bg-itqan-green-50 sm:p-1 sm:pr-4 rounded-full cursor-pointer"
              data-dropdown-toggle="dropdownDivider"
            >
              <img
                src={profilePicture || "/assets/images/logo512.png"}
                className="w-8 h-8 cursor-pointer rounded-full"
                alt="profile"
                id="dropdownDividerButton"
              />
              <div className="font-semibold hidden sm:flex flex-col">
                {/* tampilkan fulName jika ada, jika tidak ada tampilkan usernamenya */}
                <span className="text-xs">{fullName || userName}</span>
                <span className="text-xs font-normal">{role}</span>
              </div>
            </div>
            <div
              id="dropdownDivider"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
            >
              <ul
                className="py-2 text-sm text-black"
                aria-labelledby="dropdownDividerButton"
              >
                <li>
                  <NavLink
                    to={"/profil"}
                    className="block px-4 py-2 hover:text-itqan-green-50"
                  >
                    Profil
                  </NavLink>
                </li>
              </ul>
              <div className="py-2">
                <button
                  onClick={handleLogout}
                  className="block w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
        {/* SideBar */}
        <aside
          id="logo-sidebar"
          className={`pt-12 fixed top-0 z-40 left-0 w-64 h-screen transition-transform duration-1000 bg-red-700 ${
            showSidebar ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-itqan-green-100">
            <NavLink to="/dashboard" className="flex items-center mb-5">
              <img
                src="/assets/images/logo.png"
                className="w-2/3 mx-auto"
                alt="MPI Logo"
              />
            </NavLink>
            {/* panggil semua menu disini */}
            <Menu handleCloseSidebar={handleCloseSidebar} />
          </div>
        </aside>
        <div
          className={` pt-12 ${
            showSidebar ? "lg:ml-64" : "ml-0"
          } transition-all duration-1000 ease-in-out`}
        >
          {title && ( // menampilkan judul halaman pada sub Navbar jika ada
            <div className="h-16 flex items-center md:px-10 px-5 font-bold text-2xl border-b-2 border-itqan-green-50">
              {title}
            </div>
          )}

          {backButton && ( // jika backButton bernilai true maka tampilkan tombol back
            <div
              onClick={() => navigate(-1)} // kembali ke halaman sebelumnya
              className="h-16 flex items-center md:px-10 px-5 font-bold text-xl cursor-pointer hover:text-itqan-green-100 border-b-2 border-itqan-green-50"
            >
              &laquo; Kembali
            </div>
          )}
          {/* children akan dirender disini */}
          <div className="md:px-10 px-5 min-h-568">{children}</div>

          {/* footer */}
          <div className="h-10 flex md:px-10 px-5 font-semibold text-lg border-t border-itqan-green-200">
            &copy; {new Date().getFullYear()} Majelis Pembinaan Itqan
          </div>
        </div>
      </AuthMiddleware>
    </>
  );
};

export default SideNavbar;
