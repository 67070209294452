import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthRoles from "../Middlewares/AuthRoles";
import SideNavbar from "../components/SideNavbar/SideNavbar";
import Preloader from "../components/Preloader/Preloader";
import Login from "../pages/Login/Login";
import NotFound from "../pages/Error/NotFound";

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Laporan = lazy(() => import("../pages/Laporan/Laporan"));
const LaporanAnggota = lazy(() => import("../pages/Laporan/LaporanAnggota"));
const LaporanKelompok = lazy(() => import("../pages/Laporan/LaporanKelompok"));
const DetailLaporanAnggota = lazy(() =>
  import("../pages/Laporan/DetailLaporanAnggota")
);
const Mutabaah = lazy(() => import("../pages/Mutabaah/Mutabaah"));
const DetailMateri = lazy(() => import("../pages/Materi/DetailMateri"));
const Kelompok = lazy(() => import("../pages/Kelompok/Kelompok"));
const TambahKelompok = lazy(() => import("../pages/Kelompok/TambahKelompok"));
const DetailKelompok = lazy(() => import("../pages/Kelompok/DetailKelompok"));
const EditKelompok = lazy(() => import("../pages/Kelompok/EditKelompok"));
const Profil = lazy(() => import("../pages/Profil/Profil"));
const Anggota = lazy(() => import("../pages/Anggota/Anggota"));
const TambahAnggota = lazy(() => import("../pages/Anggota/TambahAnggota"));
const AmalUsaha = lazy(() => import("../pages/AmalUsaha/AmalUsaha"));
const TambahAmalUsaha = lazy(() =>
  import("../pages/AmalUsaha/TambahAmalUsaha")
);
const TambahIbadah = lazy(() => import("../pages/Ibadah/TambahIbadah"));
const EditIbadah = lazy(() => import("../pages/Ibadah/EditIbadah"));
const Ibadah = lazy(() => import("../pages/Ibadah/Ibadah"));
const Materi = lazy(() => import("../pages/Materi/Materi"));
const TambahMateri = lazy(() => import("../pages/Materi/TambahMateri"));
const EditMateri = lazy(() => import("../pages/Materi/EditMateri"));

const Router = () => {
  const nickName = useSelector((state) => state.auth.nickName);
  const userName = useSelector((state) => state.auth.userName);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 0 && hour < 12) {
      setGreeting(`Selamat Pagi, ${nickName || userName}`);
    } else if (hour >= 12 && hour < 15) {
      setGreeting(`Selamat Siang, ${nickName || userName}`);
    } else if (hour >= 15 && hour < 18) {
      setGreeting(`Selamat Sore, ${nickName || userName}`);
    } else {
      setGreeting(`Selamat Malam, ${nickName || userName}`);
    }
  }, [nickName]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <SideNavbar title={greeting}>
            <Suspense fallback={<Preloader />}>
              <Dashboard />
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan"
        element={
          <SideNavbar title="Laporan">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Anggota", "Mas'ul", "Murabbi"]}>
                <Laporan />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-anggota"
        element={
          <SideNavbar title="Laporan Anggota">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <LaporanAnggota />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-anggota/:id"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <DetailLaporanAnggota />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-kelompok"
        element={
          <SideNavbar title="Laporan Kelompok">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <LaporanKelompok />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/materi"
        element={
          <SideNavbar title="Materi">
            <Suspense fallback={<Preloader />}>
              <Materi />
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/materi/tambah-materi"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin", "Murabbi"]}>
                <TambahMateri />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/materi/edit-materi/:id"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin", "Murabbi"]}>
                <EditMateri />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/materi/:id"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <DetailMateri />
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/mutabaah-yaumiyah"
        element={
          <SideNavbar title="Mutaba'ah Yaumiyah">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Mas'ul", "Murabbi", "Anggota"]}>
                <Mutabaah />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />

      <Route
        path="/kelompok"
        element={
          <SideNavbar title="Kelompok">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin", "Murabbi", "Mas'ul"]}>
                <Kelompok />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/tambah-kelompok"
        element={
          <SideNavbar title="Kelompok">
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <TambahKelompok />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/:id"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Murabbi", "Admin", "Mas'ul"]}>
                <DetailKelompok />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/edit-kelompok/:id"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <EditKelompok />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/profil"
        element={
          <SideNavbar backButton={true}>
            <Suspense fallback={<Preloader />}>
              <Profil />
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/anggota"
        element={
          <SideNavbar title={"Anggota"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <Anggota />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/amal-usaha"
        element={
          <SideNavbar title={"Amal Usaha"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <AmalUsaha />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/amal-usaha/tambah-amal-usaha"
        element={
          <SideNavbar title={"Tambah Amal Usaha"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <TambahAmalUsaha />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/anggota/tambah-anggota"
        element={
          <SideNavbar title={"Tambah Anggota"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <TambahAnggota />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah"
        element={
          <SideNavbar title={"Ibadah"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <Ibadah />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah/tambah-ibadah"
        element={
          <SideNavbar title={"Tambah Ibadah"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <TambahIbadah />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah/edit-ibadah/:id"
        element={
          <SideNavbar title={"Edit Ibadah"}>
            <Suspense fallback={<Preloader />}>
              <AuthRoles roles={["Admin"]}>
                <EditIbadah />
              </AuthRoles>
            </Suspense>
          </SideNavbar>
        }
      />
    </Routes>
  );
};

export default Router;
